<template>
  <v-dialog
    v-model="dialog"
    content-class="confirm-dialog"
    :max-width="480"
  >
    <v-card class="font-inter bg-white">
      <v-card-text class="py-8 px-sm-10">
        <v-flex class="d-flex align-start">
          <div class="title">
            {{ title }}
          </div>
          <v-icon
            class="mt-1 ml-4 pointer"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-flex>
        <v-flex class="mt-4">
          <p
            class="description text-start"
            v-html="description"
          />
        </v-flex>
        <v-row class="mt-2">
          <v-col
            v-for="(action, index) of actions"
            :key="index"
            cols="6"
          >
            <v-btn
              v-if="action.color === 'secondary'"
              depressed
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="$emit(action.action)"
            >
              {{ action.label }}
            </v-btn>
            <v-btn
              v-if="action.color === 'danger'"
              depressed
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="danger"
              @click="$emit(action.action)"
            >
              {{ action.label }}
            </v-btn>
            <v-btn
              v-if="action.color === 'primary'"
              depressed
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="primary"
              @click="$emit(action.action)"
            >
              {{ action.label }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  props: {
    value: Boolean,
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    actions: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  height: unset;
}

.title {
  max-width: 366px;
  font-family: Inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: start;
  color: #0c111d;
}

.description {
  color: #667085;
}

.v-btn.primary {
  background: #f2284e;
  border-radius: 6px;
  color: #ffffff !important;
  text-transform: none;
}
</style>
