export const handleNetworkStatusError = {
  methods: {
    async redirectOnError(statusCode) {
      if (statusCode === 401) {
        this.$store.commit('user/setUser', null);
        this.$store.commit('user/setCurrentAccount', null);
        localStorage.setItem('user', JSON.stringify(null));
        localStorage.setItem('orgs', JSON.stringify(null));
        this.$router.push({ name: 'Login' });
      }
      if (statusCode === 404 || statusCode === 403) {
        this.$router.push({ name: '404' });
      }
      if (statusCode === 424) {
        this.$router.push({ name: 'Setup' });
      }
    }
  }
}
